import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./list-rides.css";
import PathConstants from "../../routes/PathConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import { throwError } from "rxjs";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import SearchFilters from "../Common/SearchFilters";
import AuthUser from "../Common/AuthUser";
import RolesConstants from "../../constants/RolesConstants";
import FiltersConstants from "../../constants/FiltersConstants";

export default function ListRidesComponent(tripList) {
  const filtersConstants = FiltersConstants();
  const [list, setList] = useState([]);
  const [query, setQuery] = useState({});
  const rideService = useService(RideService);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRides, setTotalRides] = useState(0);
  const [filterBy, setFilterBy] = useState();
  const [reload, setReload] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [fetchNewData, setFetchNewData] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { user } = AuthUser();
  useEffect(() => {
    // console.log(tripList);
    if (!fetchNewData && tripList?.tripList?.rides?.data) {
      console.log(tripList?.tripList?.query);
      setList(tripList.tripList.rides?.data);
      setCurrentPage(tripList.tripList.rides?.current_page);
      setLastPage(tripList.tripList.rides?.last_page);
      setQuery(tripList?.tripList?.query);
      // setQuery(tripList?.tripList)
      // setQuery(prevItems => [...prevItems, tripList?.tripList?.query]);

      setTotalRides(tripList.tripList.rides?.total);
      setHasLoaded(true);
    } else {
      console.log(query);
      fetchMoreData();
    }
  }, [reload]);

  const getHoursFromMilliSec = ms => {
    return Math.trunc(ms / (1000 * 60 * 60));
  };

  const getMinsFromMilliSec = ms => {
    return Math.trunc((ms / (1000 * 60)) % 60);
  };
  const fetchMoreData = () => {
    if (hasLoaded) {
      console.log(list);
      if (currentPage >= lastPage) {
        // setLoading(false);
        return;
      }
      // if (query && query.length > 0) {
      console.log(query);
      searchRides();
    }
  };
  const searchRides = () => {
    setLoading(true);
    console.log(query);
    rideService
      .search({
        from: query?.from,
        to: query?.to,
        departure: query?.departure,
        page: currentPage + 1,
        sort_by: sortBy,
        filter_by: filterBy,
      })
      // .search({ from: from, to: to, departure: departureDate })
      .subscribe({
        next: response => {
          if (response.status) {
            console.log(list);
            var newList = list.concat(response.result.data);
            setList(
              newList //.concat(Array.from(response.result.data)),
            );
            setCurrentPage(response?.result?.current_page);
            setLastPage(response?.result?.last_page);
          }
          setLoading(false);
        },
        error: err => {
          // alert("network error");
          setLoading(false);
          console.log("test");
          setList(
            [] //.concat(Array.from(response.result.data)),
          );

          return throwError(() => err);
        },
      });
  };
  function handleSortByAction(sortBy) {
    console.log(sortBy);
    setSortBy(sortBy);
    reloadMyBookings();
  }
  function handleFilterByAction(filterBy) {
    console.log(filterBy);
    setFilterBy(filterBy);
    reloadMyBookings();
  }
  const reloadMyBookings = () => {
    setList([]);
    setCurrentPage(0);
    setLastPage(1);
    setFetchNewData(true);
    setReload(!reload); //above use effect will run on change of reload value
  };
  return (
    <div>
      <div className="search-results container mt-3">
        <div className="row justify-content-center">
          <aside className="col-lg-8">
            {/* //////////// */}
            <SearchFilters
              sendSortByToParent={handleSortByAction}
              sendFilterByToParent={handleFilterByAction}
            ></SearchFilters>
            {/* ////////Sorting Drpdown Ends///////// */}
            {/* {loading && (
              <div class="text-center">
                <div className="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )} */}
            {hasLoaded && (
              <div className="grid search-list">
                <div className="grid-body">
                  {/* <div className="row"> */}
                  {/* <!-- BEGIN SEARCH RESULT --> */}
                  {/* new Table here */}
                  <div className="text-danger">
                    {totalRides +
                      " Rides found on " +
                      new Date(query.departure).toLocaleDateString()}
                  </div>
                  {totalRides > 0 && (
                    <div className="row d-flex justify-content-center">
                      <div
                        className="col-10 text-left table"
                        style={{ overflow: "hidden" }}
                      >
                        {/* <div className="table-responsive"> */}
                        {/* <strong>Ride Details</strong> */}
                        <InfiniteScroll
                          dataLength={list?.length}
                          next={fetchMoreData}
                          hasMore={true}
                          loader={
                            loading && (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            )
                          }
                        >
                          {list.map(trip => {
                            return (
                              <div
                                className="shadow mb-5 card card-stepper bg-image hover-overlay"
                                style={{ borderColor: "lawngreen" }}
                                key={trip._id}
                              >
                                <div className="1border card-body p-0">
                                  <div className="d-flex flex-column flex-md-row">
                                    <div className="col py-2 justify-content-between 1border d-flex flex-row border-bottom 1rounded">
                                      <div className="text-break 1col-auto d-flex flex-column text-center">
                                        <div className="text-capitalize">
                                          {trip.from_loc?.city}
                                        </div>
                                        <div className="text-capitalize text-muted small">
                                          {trip.from_loc?.state}
                                        </div>
                                        <small>
                                          {/* {new Date(
                                          parseInt(trip.departure)
                                        ).toDateString()}{" "} */}
                                          {new Date(
                                            parseInt(trip.departure)
                                          ).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            hour12: true, // Delete for 24-hour format
                                            minute: "2-digit",
                                          })}
                                        </small>
                                      </div>
                                      <div className="col d-flex flex-column text-center">
                                        {trip.distance && (
                                          <small>
                                            {trip.distance}
                                            Km.
                                          </small>
                                        )}
                                        {trip.distance && (
                                          <hr className="my-0 1flex-fill track-line" />
                                        )}
                                        {!trip.distance && (
                                          <hr className="my-0 mt-0 flex-fill track-line" />
                                        )}
                                        {}
                                        {trip.arrival && trip.departure && (
                                          <small>
                                            {getHoursFromMilliSec(
                                              trip.arrival - trip.departure
                                            ) + " Hrs. "}
                                            {getMinsFromMilliSec(
                                              trip.arrival - trip.departure
                                            ) + " Mins."}
                                          </small>
                                        )}
                                      </div>
                                      <div className="text-break 1col-auto d-flex flex-column text-center">
                                        <div className="text-capitalize">
                                          {trip.to_loc?.city}
                                        </div>
                                        <div className="text-capitalize text-muted small">
                                          {trip.to_loc?.state}
                                        </div>
                                        {trip.arrival && (
                                          <small>
                                            {new Date(
                                              parseInt(trip.arrival)
                                            ).toLocaleTimeString("en-US", {
                                              hour: "2-digit",
                                              hour12: true, // Delete for 24-hour format
                                              minute: "2-digit",
                                            })}
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col col-md-4 py-2 text-center border-bottom ">
                                      <div className="row">
                                        <div className="col">
                                          <div className="row">
                                            <div className="col  p-0">
                                              <strong>
                                                Rs.
                                                {trip.fare}
                                              </strong>
                                            </div>
                                          </div>
                                          <hr className="m-1" />
                                          <div className="row">
                                            <div className="col  p-0">
                                              {trip.current_vacancy === 0
                                                ? "FULL"
                                                : trip.current_vacancy +
                                                  " of " +
                                                  trip.maxpax +
                                                  " Avl"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex flex-row text-left ">
                                    {trip.driver && (
                                      <div className="py-3 col border-right">
                                        <div className="text-center">
                                          <strong>Driver </strong>{" "}
                                        </div>
                                        <div className="text-capitalize pr-2">
                                          {trip.driver?.name}
                                        </div>
                                        <div className="text-capitalize pr-2">
                                          {trip.driver?.age}
                                        </div>
                                        <div className="text-capitalize pr-2">
                                          {" "}
                                          {filtersConstants.getGenderString(
                                            trip.driver?.gender
                                          )}
                                          {}
                                        </div>
                                      </div>
                                    )}
                                    {trip.vehicle && (
                                      <div className="py-3 col">
                                        <div className="text-center">
                                          <strong>Vehicle</strong>
                                        </div>
                                        {trip.vehicle?.brand && (
                                          <small className="text-capitalize">
                                            {trip.vehicle.brand}
                                          </small>
                                        )}
                                        <small>
                                          {" " + trip.vehicle?.vehicle_no}
                                        </small>
                                      </div>
                                    )}
                                    {!(
                                      user?.role === RolesConstants.DRIVER ||
                                      user?.role === RolesConstants.OPERATOR
                                    ) &&
                                      trip.current_vacancy > 0 && (
                                        <div className="border-left col 1rounded 1m-1 p-2 text-right">
                                          <Link
                                            className="btn btn-outline-success"
                                            // disabled="true"
                                            to={
                                              PathConstants.BOOK_RIDE +
                                              "/" +
                                              trip._id
                                            }
                                          >
                                            Book Now
                                          </Link>{" "}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                        {/* </div> */}
                      </div>
                    </div>
                  )}
                  {/* new table ends here */}
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
    </div>
  );
}
