import { EMPTY, Observable } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import { Booking } from "../models/Booking";

export default class BookingService extends ErrorHandling implements IService {
  constructor(context: MessageContextProps) {
    super(context);
  }

  fetchMyBookings<Booking>(query: any): Observable<Booking[]> {
    return api.get<Booking[]>(`/bookings/mybookings`, undefined, query).pipe(
      catchError((err: any) => {
        this.handleError("Booking error");
        console.log(err);
        return [];
      })
    );
  }
  //get all bookings of a ride
  getBookingsOfARide<Booking>(id: string, query: any): Observable<Booking[]> {
    return api.get<Booking[]>(`/bookings/ride/` + id, undefined, query).pipe(
      catchError((err: any) => {
        this.handleError("Booking error");
        console.log(err);
        return [];
      })
    );
  }
  get<Booking>(id: string): Observable<Booking[]> {
    return api.get<Booking[]>(`/bookings/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        console.log(err);
        return [];
      })
    );
  }
  // post<Ride>(arg: Ride): Observable<Ride[]> {
  post<Booking>(obj: object): Observable<Booking> {
    return api
      .post<any>(`/bookings/book`, obj)
      .pipe
      // catchError((err: any) => {
      // this.handleError("Ride error");
      //   // console.log(err);
      // return err;
      // })
      ();
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }
  // post<T>(arg: T): Observable<T[]> {
  //   throw new Error("Method not implemented.");
  // }
  delete<T>(id: string): Observable<T[]> {
    throw new Error("Method not implemented.");
  }

  cancelBooking<Booking>(id: string): Observable<Booking> {
    return api.post<any>(`/bookings/` + id + `/cancel`, {}).pipe(
      catchError((err: any) => {
        this.handleError("Booking error");
        throw err;
      })
    );
  }
}
