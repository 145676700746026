import { useRef, useState } from "react";
import "../../../styles/search.css";
// import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import PathConstants from "../../../routes/PathConstants";
import RideService from "../../../Services/RideService";
import useService from "../../../hooks/useService";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import React from "react";
import { toast } from "react-toastify";
// import { getTime } from "react-datepicker/dist/date_utils";

export default function SearchForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state ? location.state : {};

  const [validated, setValidated] = useState(false);
  const [departureDate, setDepartureDate] = useState();
  const [inputs, setInputs] = useState(
    // state && state.inputs ? state.inputs :
    {}
  );
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialMsg, setInitialMsg] = useState(false);
  const [errors, setErrors] = useState({ from: {}, to: {}, departure: {} });
  // console.log(inputs);
  const rideService = useService(RideService);
  const reinitializeErrors = () => {
    setErrors({ from: {}, to: {}, departure: {} });
    // setErrors(values => ({ ...values, [name]: value }));
  };
  const setInitialValues = event => {
    setLocations([]);
    setInitialMsg(true);
  };
  const searchLocation = event => {
    // setInitialMsg(false);
    // console.log(event);
    // return;
    reinitializeErrors();
    if (event.altKey || event.ctrlKey || event.shiftKey) {
      return;
    }

    var value = event;//.target.value + event.key;
    console.log(value);
    setLoading(true);
    // return;
    // setInitialMsg(false);
    rideService.searchLocation(value).subscribe({
      next: response => {
        if (response.status !== 0) {
          // console.log(response.result);
          var locations = [];
          response.result.map(location => {
            return locations.push({
              label: location.city,
              value: location.city,
              state: location.state,
              landmark: location.landmark,
              id: location._id,
            });
          });
          // console.log(locations);
          setLocations(locations);
          setLoading(false);
          setInitialMsg(false);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };

  const notFoundMessage = event => {
    if (loading) {
      return `Loading`;
    } else if (!loading && !initialMsg) {
      // && (!locations || location.length <= 0)) {
      return "No result";
    } else {
      return "Search";
    }
  };
  const getSelectOptionLabel = option => {
    // console.log(option);
    //label = address, name = sec name, value = prim name
    return option.label + " -> " + option.state + " (" + option.landmark + ")";
    // return option.value + " -> " + option.name + " // " + option.label;
    // return <div>test</div>;
  };
  const handleSelectChange = (event, type) => {
    reinitializeErrors();
    const name = type;
    var value = event?.id;

    setInputs(values => ({ ...values, [name]: value }));
  };
  const handleChange = event => {
    console.log(event);
    const name = event?.target?.name;
    var value = event?.target?.value;
    if (name === "departure") {
      handleDepartureDateChange(event);
    } else if (name === "fare" || name === "distance" || name === "maxpax") {
      value = parseInt(value);
    }
    setInputs(values => ({ ...values, [name]: value }));
    setInitialMsg(true);
  };

  const getDepartureTimeForSubmission = departure => {
    let currentDateMillisec = new Date(new Date().toDateString()).getTime();
    let currentTimeMillisecDiff =
      new Date().getTime() - new Date(new Date().toDateString()).getTime();
    var selectedDateMilisec = new Date(
      new Date(departure).toDateString()
    ).getTime();
    let value = selectedDateMilisec;
    if (selectedDateMilisec < 12 * 60 * 60 * 1000 + currentDateMillisec)
      // + diff;
      value = selectedDateMilisec + currentTimeMillisecDiff;
    return value;
  };
  const handleDepartureDateChange = event => {
    const val = event.target.value;
    setDepartureDate(val);
    console.log(val);
  };
  const getSearchResults = event => {
    let queryInputs = {
      from: inputs.from,
      to: inputs.to,
      departure: inputs.departure,
    };
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      var name = "",
        value = {};
      event.preventDefault();
      if (!queryInputs.from) {
        name = "from";
        value = { required: true };

        setErrors(values => ({ ...values, [name]: value }));
      }
      if (!queryInputs.to) {
        name = "to";
        value = { required: true };
        console.log("null");
        setErrors(values => ({ ...values, [name]: value }));
      }
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      // setErrors(values => ({ ...values, [name]: value }));
      return;
    }
    let departure = getDepartureTimeForSubmission(queryInputs?.departure);
    if (departure < new Date().getTime()) {
      alert("Departure cannot be in past");
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      queryInputs.departure = departure;
      console.log(inputs);
      event.preventDefault();
      navigate(PathConstants.SEARCH_RESULTS, {
        state: {
          inputs: queryInputs,
          refresh: Math.random(),
          query: queryInputs,
        },
        // replace: true,
      });
    }
  };
  return (
    <div style={{ backgroundColor: "light" }}>
      <section className="search-sec app-bg-color">
        <div className="container">
          <Form validated={validated} onSubmit={getSearchResults} noValidate>
            <div className="row">
              <div className="col-lg-12">
                <div className="row justify-content-center">
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div className="form-outline">
                      {/* <input
                        type="text"
                        className="form-control form-control-lg"
                        name="from"
                        placeholder="From"
                        value={inputs.from || ""}
                        onChange={handleChange}
                        // ref={fromInputRef}   data-mdb-input-init
                        required
                      />{" "} */}
                      <Select
                        className={
                          errors?.from?.required
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        options={locations}
                        name="from"
                        placeholder="From"
                        onChange={event => handleSelectChange(event, "from")}
                        // onKeyDown={searchLocation}
                        onInputChange={searchLocation}
                        noOptionsMessage={event => notFoundMessage()}
                        getOptionLabel={option => getSelectOptionLabel(option)}
                        required
                        onFocus={setInitialValues}
                        // }
                        // cacheOptions
                      />
                      {/* {errors?.from?.required && (
                        <small className="text-danger">
                          From can't be empty
                        </small>
                      )} */}
                    </div>
                  </div>

                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div data-mdb-input-init className="form-outline">
                      <Select
                        className={
                          errors?.to?.required
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        options={locations}
                        name="to"
                        placeholder="To"
                        onChange={event => handleSelectChange(event, "to")}
                        onInputChange={searchLocation}
                        noOptionsMessage={event => notFoundMessage()}
                        getOptionLabel={option => getSelectOptionLabel(option)}
                        // cacheOptions
                      />
                      {/* {errors?.to?.required && (
                        <small className="text-danger">To can't be empty</small>
                      )} */}
                    </div>
                  </div>
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div data-mdb-input-init className="form-outline">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        name="departure"
                        placeholder="Departure Time"
                        value={departureDate || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted text-white">
                        Departure Date
                      </label>
                    </div>
                  </div>
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0">
                    <div data-mdb-input-init className="1form-outline">
                      <button
                        type="submit"
                        className="font-weight-bold btn btn-lg bg-dark text-white btn-outline-light btn-block"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </div>
  );
}
