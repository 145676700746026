import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
// import routes from "./Components/Routes";
import Dashboard from "./Components/rides/Dashboard";
import CreateRideComponent from "./Components/rides/CreateRideComponent";
import PathConstants from "./routes/PathConstants";
import { StrictMode } from "react";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
// console.log("not guest");
// initialize rotues and navi links
// const initRoutes = () => (
//   <div className="container">
//     <Routes>
//       {" "}
//       {/* <Route path="/" element={<Home />}></Route> */}{" "}
//       {/* <Route path="/dashboard/*" element={<Dashboard />}>
//                                 {" "}
//                               </Route>{" "} */}{" "}
//     </Routes>{" "}
//   </div>
// );

// const initializedRoutes = initRoutes();
// // ReactDOM.render(
// //   initializedRoutes,
// //   document.getElementById("root")
// // );
root.render(
  // <StrictMode>
  <>
    <App />
    <ToastContainer /> {/* </StrictMode> */}{" "}
  </>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
