const BookingStatus = {
  CONFIRMED: "CN",
  CANCELLED_BY_RIDER: "CR",
  CANCELLED_BY_OPERATOR: "CO",
  CANCELLED: "C",

  //Names of statuses
  CONFIRMED_NAME: "CONFIRMED",
  CANCELLED_NAME: "CANCELLED",

  UPCOMING: "U",
  PAST: "P",
};
export default BookingStatus;
