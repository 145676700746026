function FiltersConstants() {
  const GenderFilters = {
    MALE: "M",
    FEMALE: "F",
    TRANS: "T",

    MALE_STRING: "Male",
    FEMALE_STRING: "Female",
    TRANS_STRING: "Transgender",
  };

  function getGenderString(gender) {
    if (gender === GenderFilters.MALE) {
      return GenderFilters.MALE_STRING;
    } else if (gender === GenderFilters.FEMALE) {
      return GenderFilters.FEMALE_STRING;
    } else if (gender === GenderFilters.TRANS) {
      return GenderFilters.TRANS_STRING;
    } else {
      return "";
    }
  }
  return {
    GenderFilters: GenderFilters,
    getGenderString,
  };
}

export default FiltersConstants;
