import { EMPTY, Observable } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import Ride from "../models/Ride";

export default class AuthService extends ErrorHandling implements IService {
  constructor(context: MessageContextProps) {
    super(context);
  }

  login<User>(email: string, password: string): Observable<User[] | void> {
    return api.post<User[]>(`/login`, { email, password }).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        // console.log(err);
        // return [];
        throw err;
      })
    );
  }
  logout(): Observable<void> {
    return api.post<void>(`/logout`, {}).pipe(
      catchError((err: any) => {
        this.handleError("Logout error");
        // console.log(err);
        throw err;
      })
    );
  }

  verifyEmail<User>(email: string, otp: number): Observable<User[] | void> {
    return api.post<User[]>(`/email/verify`, { email, otp }).pipe(
      catchError((err: any) => {
        this.handleError(err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        throw err;
        // return [];
      })
    );
  }
  resendOtp<User>(email: string): Observable<User[] | void> {
    return api.post<User[]>(`/email/verify/resend`, { email }).pipe(
      catchError((err: any) => {
        this.handleError(err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        throw err;
      })
    );
  }
  sendForgotPasswordOtpEmail<User>(user?: object, email?: string): Observable<User[] | void> {
    var path = `/forgot/password/email`;
    if(user) {
      path = `/forgot/password/user/email`;
    }
    return api.post<User[]>(path, { email }).pipe(
      catchError((err: any) => {
        this.handleError(err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        throw err;
      })
    );
  }
  resetForgotPasswordThroughEmail<User>(
    input: object, user?: object
  ): Observable<User[] | void> {
    var path = `/forgot/password/reset/email`;
    if(user) {
      path = `/forgot/password/reset/user/email`;
    }
    return api.post<User[]>(path, input).pipe(
      catchError((err: any) => {
        this.handleError(err?.response?.data?.message);
        console.log(err);
        throw err;
      })
    );
  }
  get<Ride>(id: string): Observable<Ride[]> {
    return api.get<Ride[]>(`/rides/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        console.log(err);
        return [];
      })
    );
  }
  // post<Ride>(arg: Ride): Observable<Ride[]> {
  post<Ride>(obj: Ride): Observable<Ride> {
    return api
      .post<any>(`/rides`, { obj })
      .pipe
      // catchError((err: any) => {
      // this.handleError("Ride error");
      //   // console.log(err);
      // return err;
      // })
      ();
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }
  // post<T>(arg: T): Observable<T[]> {
  //   throw new Error("Method not implemented.");
  // }
  delete<T>(id: string): Observable<T[]> {
    throw new Error("Method not implemented.");
  }

  register<User>(input: object): Observable<User[] | void> {
    return api.post<User[]>(`/register`, input).pipe(
      catchError((err: any) => {
        this.handleError("Register error");
        // console.log(err);
        // return [];
        throw err;
      })
    );
  }
}
