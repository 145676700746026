import { useEffect, useState } from "react";
import SearchForm from "../Common/SearchComponent/SearchForm";
import { useLocation } from "react-router-dom";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import { throwError } from "rxjs";
import ListRidesComponent from "./ListRidesComponent";

export default function SearchResults() {
  const location = useLocation();
  const state = location.state ? location.state : {};
  const [loading, setLoading] = useState(2);
  const [rides, setRides] = useState([]);
  const rideService = useService(RideService);
  // console.log(state.refresh);
  useEffect(() => {
    // console.log(state.refresh);
    if (state && state.inputs && Object.entries(state.inputs).length > 0) {
      setLoading(1);
      rideService.search(state.inputs).subscribe({
        next: response => {
          if (response.status) {
            console.log(response);
            setRides(response.result);
          }
          setLoading(0);
        },
        error: err => {
          // alert("network error");
          setLoading(0);
          return throwError(() => err);
        },
      });
    }
  }, [state.refresh]);
  // state.refresh;
  // state.inputs,
  return (
    <div>
      <SearchForm inputs={state?.inputs}></SearchForm>
      {loading === 1 && (
        <div className="mt-5 1 text-center text-success">
          <span className="spinner-border" role="status"></span>
          <strong>Just a moment, searching rides for you...</strong>
        </div>
      )}
      {/* {loading === 0 && (
        <div>
          <strong>{ state.inputs.from}</strong>
        </div>
      )} */}
      {loading === 0 &&
        (rides?.data?.length > 0 ? (
          <ListRidesComponent
            tripList={{
              rides: rides,
              query: {
                from: state.inputs?.from,
                to: state.inputs?.to,
                departure: state.inputs?.departure,
                // departure: dateInputRef?.current?.value,
              },
            }}
          />
        ) : (
          <strong>
            <div className="mt-5 text-center text-danger">No rides found</div>
          </strong>
        ))}
    </div>
  );
}
