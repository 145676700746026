import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
// import Form from "react-bootstrap/FormSelect";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import PathConstants from "../../routes/PathConstants";
import "../../styles/create-ride-form.css";
import "react-datepicker/dist/react-datepicker.css";
import DriverService from "../../Services/DriverService";
import VehicleService from "../../Services/VehicleService";
import { toast } from "react-toastify";
import Select from "react-select";
import AddNewLocationDialog from "../dialogs/AddNewLocationDialog";

export default function CreateRideComponent() {
  const [inputs, setInputs] = useState({ driver_id: "", vehicle_id: "" });
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialMsg, setInitialMsg] = useState(false);

  const [validated, setValidated] = useState(false);
  const [departureDate, setDepartureDate] = useState();
  const [arrivalDate, setArrivalDate] = useState();
  const [myDrivers, setMyDrivers] = useState();
  const [myVehicles, setMyVehicles] = useState();
  const [openNewLocationDialog, setOpenNewLocationDialog] = useState(false);
  const [errors, setErrors] = useState({ from: {}, to: {}, departure: {} });

  const rideService = useService(RideService);
  const driverService = useService(DriverService);
  const vehicleService = useService(VehicleService);

  useEffect(() => {
    getMyDrivers();
    getMyVehicles();
  }, []);

  const reinitializeErrors = () => {
    setErrors({ from: {}, to: {} });
    // setErrors(values => ({ ...values, [name]: value }));
  };

  const getMyDrivers = () => {
    driverService.getMyDrivers().subscribe(res => {
      // console.log(res.result?.data);
      setMyDrivers(res.result?.data);
    });
  };

  const handleSelectChange = (event, type) => {
    reinitializeErrors();
    const name = type;
    console.log(event);
    var value = event?.id;

    setInputs(values => ({ ...values, [name]: value }));
  };

  const getSelectOptionLabel = option => {
    // console.log(option);
    return option.label + " -> " + option.state + " (" + option.landmark + ")";
    // return option.label + " -> " + option.name + " (" + option.value + ")";
    // return <div>test</div>;
  };
  const searchLocation = event => {
    if (event.altKey || event.ctrlKey || event.shiftKey) {
      return;
    }
    var value = event;//.target.value + event.key;
    // console.log(value);
    setLoading(true);
    rideService.searchLocation(value).subscribe({
      next: response => {
        if (response.status !== 0) {
          // console.log(response.result);
          var locations = [];
          response.result.map(location => {
            return locations.push({
              label: location.city,
              value: location.city,
              state: location.state,
              landmark: location.landmark,
              id: location._id,

              // + " : " + location.state, //location.landmark
              // // value: location._id,
              // value: location.state,
            });
          });
          setInitialMsg(false);
          setLocations(locations);
          // setLocations(values => ({ ...values, locations }));
          setLoading(false);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const setInitialValues = event => {
    setLocations([]);
    setInitialMsg(true);
  };
  const notFoundMessage = event => {
    if (loading) {
      return `Loading`;
    } else if (!loading && !initialMsg) {
      // && (!locations || location.length <= 0)) {
      return "No result";
    } else {
      return "Search";
    }
  };
  const getMyVehicles = () => {
    vehicleService.getMyVehicles().subscribe(res => {
      // console.log(res.result?.data);
      setMyVehicles(res.result?.data);
    });
  };

  const createRide = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      var name = "",
        value = {};
      console.log(inputs.from);
      event.preventDefault();
      event.stopPropagation();
      if (!inputs.from) {
        name = "from";
        console.log("here");
        value = { required: true };

        setErrors(values => ({ ...values, [name]: value }));
      }
      if (!inputs.to) {
        name = "to";
        value = { required: true };

        setErrors(values => ({ ...values, [name]: value }));
      }
      setValidated(true);
      return;
    } else {
      if (inputs?.departure < new Date().getTime()) {
        alert("Departure cannot be in past");
        event.stopPropagation();
        setValidated(true);
        return;
      }

      if (inputs?.departure > inputs?.arrival) {
        alert("Arrival cannot be before Departure");
        event.stopPropagation();
        setValidated(true);
        return;
      }
      rideService.post(inputs).subscribe({
        next: rides => {
          if (rides.status) {
            toast.success(rides.message);
            navigate(PathConstants.RIDE_DETAIL, { state: rides.result });
          }
        },
        error: e => alert(e),
        complete: () => console.info("complete"),
      });
    }
  };
  const handleChange = event => {
    const name = event.target.name;
    var value = event.target.value;
    if (name === "departure") {
      value = new Date(event.target.value).getTime();
      handleDepartureDateChange(event);
    } else if (name === "arrival") {
      value = new Date(event.target.value).getTime();
      handleArrivalDateChange(event);
    } else if (name === "fare" || name === "distance" || name === "maxpax") {
      value = parseInt(value);
    }
    setInputs(values => ({ ...values, [name]: value }));
    setInitialMsg(true);
  };

  const handleDepartureDateChange = event => {
    const val = event.target.value;
    setDepartureDate(val);
  };

  const handleArrivalDateChange = event => {
    const val = event.target.value;
    setArrivalDate(val);
  };

  const handleSubmit = event => {
    event.preventDefault();
    createRide(event);
  };

  const createLocationDialog = () => {
    setOpenNewLocationDialog(true);
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenNewLocationDialog(false);
    console.log(confirmation);
  }
  return (
    <div className="App create-ride-form">
      <AddNewLocationDialog
        open={openNewLocationDialog}
        // inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></AddNewLocationDialog>
      <div className="mask d-flex align-items-center h-100 app-bg-color">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{ borderRadius: 15 }}>
                <div className="card-body p-5">
                  <Form
                    onSubmit={handleSubmit}
                    validated={validated}
                    noValidate
                  >
                    <div className="h4 text-left">New Ride</div>
                    <div className="text-right col">
                      <span
                        onClick={createLocationDialog}
                        className="text-primary btn"
                      >
                        Location not found? Add Here
                      </span>
                    </div>
                    <div
                      data-mdb-input-init
                      className="text-left form-outline mb-4"
                    >
                      <Select
                        className={
                          errors?.from?.required
                            ? "is-invalid form-control"
                            : "form-control"
                        }
                        options={locations}
                        name="from"
                        placeholder="From"
                        onChange={event => handleSelectChange(event, "from")}
                        // onKeyDown={searchLocation}
                        onInputChange={searchLocation}
                        noOptionsMessage={event => notFoundMessage()}
                        getOptionLabel={option => getSelectOptionLabel(option)}
                        onFocus={setInitialValues}
                        required
                      />
                    </div>
                    <hr />
                    <div
                      data-mdb-input-init
                      className="text-left form-outline mb-4"
                    >
                      <Select
                        className={
                          errors?.to?.required
                            ? "is-invalid form-control"
                            : "form-control"
                        }
                        options={locations}
                        name="to"
                        placeholder="To"
                        onChange={event => handleSelectChange(event, "to")}
                        // onKeyDown={searchLocation}
                        onInputChange={searchLocation}
                        noOptionsMessage={event => notFoundMessage()}
                        getOptionLabel={option => getSelectOptionLabel(option)}
                        // }
                        onFocus={setInitialValues}
                        required
                      />
                    </div>
                    <hr />
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="datetime-local"
                        className="form-control form-control-lg"
                        name="departure"
                        placeholder="Departure Time"
                        value={departureDate || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted">
                        Departure Date & Time
                      </label>
                    </div>
                    <hr />
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="datetime-local"
                        // timeFormat="HH:mm"
                        className="form-control form-control-lg"
                        name="arrival"
                        placeholder="Arrival Time"
                        value={arrivalDate || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted">
                        Arrival Date & Time
                      </label>
                    </div>
                    <hr />
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="fare"
                        min="10"
                        placeholder="Trip Fare"
                        value={inputs.fare || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted">
                        In India Rupees (Min: 10 Rs.)
                      </label>
                    </div>

                    <hr />
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="maxpax"
                        max="10"
                        min="1"
                        placeholder="Max Available Seats"
                        value={inputs.maxpax || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted">
                        Except driver seat (Min: 1, Max: 10)
                      </label>
                    </div>

                    <hr />
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="distance"
                        max="5000"
                        min="1"
                        placeholder="Total Distance"
                        value={inputs.distance || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted">
                        In Km. (Min: 1 Km, Max: 5000 Km)
                      </label>
                    </div>

                    <hr />
                    <Form.Select
                      className="text-capitalize form-select form-select-lg is-1invalid"
                      aria-label="Default select example"
                      id="driver_id"
                      name="driver_id"
                      value={inputs.driver_id || ""}
                      // onChange={handleSelectChangeDriver}
                      onChange={handleChange}
                      required
                    >
                      <option disabled value={""}>
                        Select Driver
                      </option>
                      {myDrivers &&
                        myDrivers.map(driver => {
                          return (
                            <option key={driver._id} value={driver._id}>
                              {driver.name + " (" + driver.phone + ")"}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <label className="col small text-left text-muted">
                    {"Add from Dashboard -> Driver"}
                      </label>
                    <hr />
                    <Form.Select
                      className="text-capitalize form-select form-select-lg"
                      aria-label="Default select example"
                      name="vehicle_id"
                      value={inputs.vehicle_id || ""}
                      onChange={handleChange}
                      required
                    >
                      <option disabled value={""}>
                        Select Vehicle
                      </option>
                      {myVehicles &&
                        myVehicles.map(vehicle => {
                          return (
                            <option
                              className="text-capitalize"
                              key={vehicle._id}
                              value={vehicle._id}
                            >
                              {vehicle.brand + " - " + vehicle.vehicle_no}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <label className="col small text-left text-muted">
                    {"Add from Dashboard -> Vehicle"}
                      </label>
                    <input className="mt-3 col btn btn-primary" type="submit" />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
