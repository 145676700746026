import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SocialShare from "./SocialShare";

export default function ShareDialog(props) {
  const DELETE = "DELETE";
  console.log(props);
  const handleClose = () => {
    props.sendConfirmationToParent(false);
  };
  const handleConfirm = () => {
    props.sendConfirmationToParent(true);
  };
  return (
    <>
      <Modal show={props.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Share"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SocialShare inputs={props.inputs}></SocialShare>
        </Modal.Body>
      </Modal>
    </>
  );
}
