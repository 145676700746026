import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import "../../styles/ride-detail.css";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import RideStatus from "../../constants/RideStatus";
import PathConstants from "../../routes/PathConstants";
import StringUtils from "../../utils/StringUtils";
import ListBookingsComponent from "../rider/ListBookingsComponent";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { toast } from "react-toastify";
import FiltersConstants from "../../constants/FiltersConstants";
import RolesConstants from "../../constants/RolesConstants";
import UserBookings from "../user/UserBookings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import ShareService from "../Share/Services/ShareService";
import ReactDOM from "react-dom/client";
// import { render } from "@testing-library/react";

export default function RideDetailComponent() {
  var { id } = useParams();
  const filtersConstants = FiltersConstants();
  const location = useLocation();
  var data = location.state ? location.state : {};
  const { user } = AuthUser;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });

  const navigate = useNavigate();
  const [ride, setRide] = useState();
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [reload, setReload] = useState(false);
  const [confirmationType, setConfirmationType] = useState(); //Delete, Cancel
  const [showBookings, setShowBookings] = useState(false);
  const [isShare, setIsShare] = useState(false);
  
  const rideService = useService(RideService);
  useEffect(() => {
    if (id) {
      console.log(id);
      getRide(id);
    } else if (data) {
      console.log(data);
      setRide(data);
      setLoading(false);
    }
  }, [reload]);

  const navigateToDashboard = () => {
    navigate(PathConstants.DASHBOARD);
  };
  const navigateToBookings = () => {};
  const getHoursFromMilliSec = ms => {
    return Math.trunc(ms / (1000 * 60 * 60));
  };

  const getMinsFromMilliSec = ms => {
    return Math.trunc((ms / (1000 * 60)) % 60);
  };

  const confirmMakeItLive = () => {
    //confirm dialog
    var title = "Make it live/running?";

    var body =
      "Riders can book a ride only after you make your ride live/running. " +
      "Do you want to make your ride live / running ? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));
    setConfirmationType(RideStatus.RUNNING);
    setOpenConfirmationDialog(true);
  };

  const makeItLive = () => {
    rideService.updateStatus(ride._id, RideStatus.RUNNING).subscribe(res => {
      console.log(res.result);
      setRide(res.result);
      setLoading(false);
    });
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    console.log(confirmation);
    if (confirmation) {
      if (confirmationType === RideStatus.DELETED) {
        deleteRide();
      } else if (confirmationType === RideStatus.CANCELLED) {
        cancelRide();
      } else if (confirmationType === RideStatus.RUNNING) {
        //making ride pending to live/running
        makeItLive();
      }
    }
  }
  const confirmCancelRide = () => {
    //confirm dialog
    var title = "Cancel Ride?";

    var body =
      "Do you want to cancel the ride? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));
    setConfirmationType(RideStatus.CANCELLED); //ConfirmationDialog.DELETE;
    setOpenConfirmationDialog(true);
  };

  const confirmDeleteRide = () => {
    //confirm dialog
    var title = "Delete Ride?";

    var body =
      "Do you want to delete the ride? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));
    setConfirmationType(RideStatus.DELETED);
    setOpenConfirmationDialog(true);
  };
  const deleteRide = () => {
    setDeleting(true);
    rideService.delete(ride._id).subscribe({
      next: response => {
        if (response.status) {
          toast.dismiss();
          toast.success(response?.message);
          navigateToDashboard();
          setReload(!reload);
        }
        setDeleting(false);
      },
      error: err => {
        setDeleting(false);
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const cancelRide = () => {
    setCancelling(true);
    rideService.cancel(ride._id).subscribe({
      next: response => {
        if (response.status) {
          toast.dismiss();
          toast.success(response.message);
          // setReload(!reload);
          // getRide(ride._id);
          if (!id) {
            navigate(PathConstants.RIDE_DETAIL + "/" + ride._id, {
              replace: true,
            });
          }
          setReload(!reload);
        }
        setCancelling(false);
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
        setCancelling(false);
      },
    });
  };

  const getRide = id => {
    setLoading(true);
    rideService.get(id).subscribe({
      next: response => {
        if (response.status) {
          setRide(response.result);
        }
        setLoading(false);
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
        setLoading(false);
      },
    });
  };

  function ShowStatus() {
    var classString = "p-2 mx-2 ",
      status = "";
    if (ride.status === RideStatus.PENDING) {
      classString += "bg-dark text-white";
      status = "PENDING";
    } else if (ride.status === RideStatus.RUNNING) {
      classString += "bg-success text-white";
      status = "RUNNING";
    } else if (ride.status === RideStatus.CANCELLED) {
      classString += "bg-danger text-white";
      status = "CANCELLED";
    }

    return <span className={"col " + classString}>{status}</span>;
  }
  const socialShare = () =>{
    // const domNode = ReactDOM.getElementById('root');
    const domNode = ReactDOM.createRoot(document.getElementById("social-share"));
    domNode.render (<ShareService link={window.location.protocol + "//" + 
        window.location.hostname + "/share/ride/" + ride._id}></ShareService>);
  //  shareService.show();
 }
  return (
    <div className="App app-bg-color">
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <div className="container">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <section
            className="vh-150"
            // style="background-color: #eee;"
          >
            <div className="container py-5 h-100">
              <div className="row">
                <div className="col">
                  {(user?.role === RolesConstants.OPERATOR ||
                    user?.role === RolesConstants.ADMIN) && (
                    <div className="row mb-3">
                      <div className="col-auto my-1">
                        <button
                          className="btn btn-sm btn-outline-dark 1border"
                          onClick={() => navigate(-1)}
                        >
                          {"< Back"}
                        </button>{" "}
                      </div>

                      <div className="col-auto my-1">
                        <div
                          onClick={navigateToDashboard}
                          className="btn btn-sm btn-outline-dark"
                        >
                          Dashboard
                        </div>
                      </div>
                      {ride.status !== RideStatus.CANCELLED && (
                        <div className="col-auto my-1">
                          <div
                            onClick={confirmCancelRide}
                            className="btn btn-sm btn-outline-dark"
                          >
                            {cancelling ? "Cancelling..." : "Cancel"}
                          </div>
                        </div>
                      )}
                      <div className="col-auto my-1">
                        <div
                          onClick={confirmDeleteRide}
                          className="btn btn-sm btn-outline-dark"
                        >
                          {deleting ? "Deleting..." : "Delete"}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col">
                  <div
                    className="1app-bg-color shadow card card-stepper"
                    // style={{ borderRadius: "10px" }}
                  >
                    <div className="card-body p-1">
                      {/* <div className="d-flex justify-content-between align-items-center"> */}
                      <div className="col display-6">
                        <strong>Ride Detail</strong>
                      </div>
                      <div className="text-right">
                      <div className="btn btn-outline-none" 
                      // onClick={e => setIsShare(true)}
                      onClick={socialShare}
                      >
                      
                      <FontAwesomeIcon className="px-1" icon={faShare} />
                      </div>
                      </div>
                      <div id="social-share"></div>
                      {/* {isShare && (<ShareService link={window.location.origin + "/share/ride/" + ride._id}></ShareService>)} */}
                      <hr />
                      <div className="col">
                        <div className="row">
                          <div className="mb-2 col text-success">
                            <strong>
                              {new Date(
                                parseInt(ride.departure)
                              ).toDateString()}
                            </strong>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-8 border-right">
                            <div className="align-items-center d-flex flex-column flex-md-row">
                              <div className="col mb-2">
                                <div className="text-capitalize">
                                  {/* {ride.from} */}
                                  {ride.from_loc?.city}
                                </div>
                                <small>
                                  {new Date(
                                    parseInt(ride.departure)
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    hour12: true, // Delete for 24-hour format
                                    minute: "2-digit",
                                  })}
                                </small>
                              </div>
                              <div className="col col-md-auto my-4 my-md-2">
                                <small>{ride.distance} Km.</small>
                                <hr className="my-0 flex-fill track-line" />
                                <small>
                                  {getHoursFromMilliSec(
                                    ride.arrival - ride.departure
                                  ) + " Hrs. "}
                                  {getMinsFromMilliSec(
                                    ride.arrival - ride.departure
                                  ) + " Mins."}
                                </small>
                              </div>
                              <div className="col mb-2">
                                <div>{ride.to_loc?.city}</div>
                                <small>
                                  {new Date(
                                    parseInt(ride.arrival)
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    hour12: true, // Delete for 24-hour format
                                    minute: "2-digit",
                                  })}
                                </small>
                              </div>
                              {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                            </div>
                          </div>
                          <div className="align-self-center col text-center">
                            <div className="row">
                              <div className="col">
                                <div className="row">
                                  <div className="col">
                                    <strong>Rs. {ride.fare}</strong>
                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col">
                                    {ride.current_vacancy}
                                    {/* // ? ride.current_vacancy */}
                                    {/* // : ride.maxpax} */}
                                    {" of "} {ride.maxpax} {" vacant"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {/* <div className="col"> */}
                      <div className="col">
                        <div className="row">
                          <div className="col d-flex ">
                            <div className="d-flex flex-column">
                              <strong className="1lead 1fw-normal">
                                Pickup-Drop
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="mt-3 col border-right">
                            <div className="d-inline-flex align-items-center flex-column flex-md-row">
                              <div className="col-auto">
                                <small className="text-muted">
                                  Pickup Location
                                </small>
                                <div className="text-capitalize">
                                  {ride.from_loc?.landmark}{" "}
                                </div>
                              </div>
                              <div className="col col-md-4 my-3">
                                <hr className="my-0 flex-fill track-line" />
                              </div>
                              <div className="col-auto">
                                <small className="text-muted">
                                  Drop Location
                                </small>
                                <div className="text-capitalize">
                                  {ride.to_loc?.landmark}
                                </div>
                              </div>
                              {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <hr />
                      <div className="col">
                        <div className="row">
                          <div className="col  border-right border-dark">
                            <div className="row">
                              <div className="col mb-3 text-left">
                                <strong className="1lead 1fw-normal">
                                  Driver Detail
                                </strong>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <small className="text-capitalize 1fw-normal">
                                  {ride?.driver?.name}
                                  {ride?.driver?.phone
                                    ? " | " + ride.driver.phone
                                    : ""}
                                  {ride?.driver?.age
                                    ? " | " + ride.driver.age
                                    : ""}
                                  {ride?.driver?.gender
                                    ? " | " +
                                      filtersConstants.getGenderString(
                                        ride.driver.gender
                                      )
                                    : ""}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col mb-3 text-left">
                                <strong className="text-capitalize 1fw-normal">
                                  Vehicle Detail
                                </strong>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <small className="text-capitalize 1fw-normal">
                                  {ride?.vehicle?.brand}
                                  {ride?.vehicle?.vehicle_no
                                    ? " | " + ride.vehicle.vehicle_no
                                    : ""}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <span className="font-weight-bold">Status</span>
                          {/* <div>{showStatus}</div> */}
                          <ShowStatus></ShowStatus>
                        </div>
                        {ride.status === RideStatus.PENDING &&
                          (user?.role === RolesConstants.OPERATOR ||
                            user?.role === RolesConstants.ADMIN) && (
                            <div onClick={confirmMakeItLive} className="col">
                              <div className="btn btn-outline-dark border border-success">
                                Make It Live
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <hr />

                    {!(ride.current_vacancy === ride.maxpax) && (
                      // (user?.role === RolesConstants.OPERATOR ||
                      //   user?.role === RolesConstants.ADMIN) && (
                      <div className="col pb-3">
                        <div className="row">
                          <div className="col">
                            <div
                              onClick={e => setShowBookings(true)}
                              className="px-5 btn btn-light border border-dark"
                            >
                              Show Bookings
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      {showBookings &&
        (user?.role === RolesConstants.OPERATOR ||
          user?.role === RolesConstants.ADMIN) && (
          <div className="contaner">
            <ListBookingsComponent rideId={ride?._id}></ListBookingsComponent>
          </div>
        )}

      {showBookings && (
        //&& user?.role === RolesConstants.RIDER && (
        <UserBookings rideId={ride?._id}></UserBookings>
        // <ListBookingsComponent rideId={ride?._id}></ListBookingsComponent>
      )}
    </div>
  );
}
