import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import ConfirmationDialog from "./ConfirmationDialog";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import { Form } from "react-bootstrap";

export default function AddNewLocationDialog(props) {
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [landmark, setLandmark] = useState();
  const [pincode, setPincode] = useState();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });

  const rideService = useService(RideService);

  useEffect(() => {
    reintInputs();
  }, [props?.open]);

  const handleClose = () => {
    setValidated(false);
    props.sendConfirmationToParent(false);
  };
  const handleAdd = event => {
    const form = event.currentTarget;
    // console.log(form);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      var inputs = {
        city: city,
        state: state,
        landmark: landmark,
        pincode: pincode,
      };

      var title = "Add Location";

      var body =
        "Do you want to proceed? Once confirmed, it cannot be revoked.";
      setDialogParams(prevState => ({
        ...prevState,
        title: title,
        body: body,
        formParams: inputs,
      }));
      // setConfirmationType(RideStatus.CANCELLED); //ConfirmationDialog.DELETE;
      setOpenConfirmationDialog(true);
      event.preventDefault();
    }
    // props.sendConfirmationToParent(true);
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    // console.log(formParams);
    if (confirmation) {
      // event.preventDefault();
      addLocation(null);
    }
  }
  const reintInputs = () => {
    setCity(undefined);
    setState(undefined);
    setLandmark(undefined);
    setPincode(undefined);
  };

  const addLocation = params => {
    setLoading(true);
    var inputs = {
      city: city,
      state: state,
      landmark: landmark,
      pincode: pincode,
    };
    {
      //register
      console.log(inputs);
      setLoading(true);
      // event.preventDefault();
      rideService.addLocation(inputs).subscribe({
        next: response => {
          console.log(response);
          if (response?.status === 0) {
            // toast.error(response?.message);
          } else {
            toast.dismiss();
            toast.success(response?.message);
            setValidated(false);
            props.sendConfirmationToParent(true);
            // reintInputs();
          }
          setLoading(false);
        },
        error: err => {
          setLoading(false);
          toast.dismiss();
          toast.error(err?.message);
        },
      });
    }
  };
  return (
    <>
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <Modal show={props.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Add New Location"}</Modal.Title>
        </Modal.Header>

        {/* {loading && ( */}
        <Form
          validated={validated}
          // class="needs-validation"
          onSubmit={handleAdd}
          noValidate
        >
          <Modal.Body>
            <div data-mdb-input-init className="form-outline mb-4">
              <input
                type="text"
                id="city"
                name="city"
                className="form-control form-control-lg"
                placeholder="City"
                onChange={e => setCity(e.target.value)}
                required
              />
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <input
                type="text"
                id="state"
                name="state"
                className="form-control form-control-lg"
                placeholder="State"
                onChange={e => setState(e.target.value)}
                required
              />
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <input
                type="text"
                id="landmark"
                name="landmark"
                className="form-control form-control-lg"
                placeholder="Landmark"
                onChange={e => setLandmark(e.target.value)}
                required
                maxLength={30}
              />
              <small className="text-muted">Max: 30 Characters</small>
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <input
                type="text"
                id="pincode"
                name="pincode"
                className="form-control form-control-lg"
                placeholder="Pincode"
                onChange={e => setPincode(e.target.value)}
              />
              <small className="text-muted">optional</small>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              className="app-bg-color border border-dark"
              variant="1primary"
              // onClick={handleAdd}
              type="submit"
            >
              {!loading && <span>Add</span>}
              {loading && (
                <div>
                  <span className="mr-2">Adding</span>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                </div>
              )}
            </Button>
          </Modal.Footer>
        </Form>
        {/* )} */}
      </Modal>
    </>
  );
}
