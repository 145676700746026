import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import CreateRideComponent from "./CreateRideComponent";
import { useNavigate } from "react-router-dom";
import PathConstants from "../../routes/PathConstants";
import DashboardService from "../../Services/DashboardService";
import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import DashboardSummary from "./DashboardOverview";
import DashboardBookingOverview from "./DashboardBooking";
import DashboardRideOverview from "./DashboardRide";
import BookingService from "../../Services/BookingService";
import BookingStatus from "../../constants/BookingStatus";
import DashboardListBookings from "./DashboardListBookings";
import { throwError } from "rxjs";

export default function DashboardRide() {
  const { user } = AuthUser();
  const navigate = useNavigate();
  const [bookingDetail, setBookingDetail] = useState();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [showBookings, setShowBookings] = useState(false);
  const dashboardService = useService(DashboardService);
  var reset = false;
  const bookingService = useService(BookingService);

  useEffect(() => {
    getBookingDashboard();
  }, []);

  const getBookingDashboard = () => {
    setLoading(true);
    dashboardService.getBookingDashboard().subscribe(res => {
      // console.log(res);
      setBookingDetail(res.result);
      setLoading(false);
      // setBookingDetail("ride detail");
      // setShowElement(OVERVIEW);
    });
  };
  function handleFetchMore(hasMore) {
    if (hasMore) {
      setLoadingBookings(true);
      // console.log("handeling fetch more");

      fetchMoreData();
    }
  }
  const getConfirmedBooking = () => {
    let query = {
      status: BookingStatus.CONFIRMED,
    };
    loadBooking(query);
  };
  const getPastBooking = () => {
    let query = {
      status: BookingStatus.PAST,
    };
    loadBooking(query);
  };
  const getUpcomingBooking = () => {
    let query = {
      status: BookingStatus.UPCOMING,
    };
    loadBooking(query);
  };
  const getCancelledBooking = () => {
    let query = {
      status: BookingStatus.CANCELLED,
    };
    loadBooking(query);
  };
  const loadBooking = newQuery => {
    // setQuery(query);
    query.status = newQuery.status;
    setShowBookings(true);
    reset = true;

    fetchBookings();
  };
  const fetchBookings = () => {
    if (reset) {
      query.page = 1;
    } else {
      query.page = currentPage + 1;
    }
    query.size = 1;
    // query.sort_by = sortBy;
    setLoadingBookings(true);
    dashboardService.fetchMyReceivedBookings(query).subscribe({
      next: response => {
        if (response.status !== 0) {
          if (reset) {
            setLastPage(response?.result?.last_page);
            // var newList = result.concat(response.result.data);
            setResult(response.result.data);
            setCurrentPage(1);
            reset = false;
          } else {
            // console.log(result);
            var newList = result.concat(response.result.data);
            setResult(
              newList //.concat(Array.from(response.result.data)),
            );
            setCurrentPage(currentPage + 1);
          }
        }
        setLoadingBookings(false);
        // setLoadingPage(false);
      },
      error: err => {
        setResult(
          [] //.concat(Array.from(response.result.data)),
        );
        setLoadingBookings(false);
        return throwError(() => err);
      },
    });
  };
  const fetchMoreData = () => {
    if (currentPage > lastPage) {
      return;
    }
    fetchBookings();
  };

  return (
    <div className="contaniner">
      <div className="row justify-content-center">
        <div class="mt-2 px-2 col-10 col-md-8 ">
          <button
            className="btn btn-outline-dark"
            onClick={getBookingDashboard}
          >
            {loading ? "Loading..." : "Reload"}
          </button>
          {loading && (
            <div className="text-center">
              <span className="text-center spinner-border" role="status"></span>
            </div>
          )}
          {!loading && (
            <div>
              <div class="row mb-3 justify-content-center">
                <div class="col-xl-3 col-sm-6 py-2">
                  <div class="card shadow text-dark text-center h-100">
                    <div
                      onClick={getConfirmedBooking}
                      class="btn card-body app-bg-color"
                    >
                      {/* <div class="rotate">
                        <i class="fa fa-user fa-4x"></i>
                      </div> */}
                      {/* <h6 class="text-uppercase">Total Confirmed Booking</h6> */}

                      <h6 className="text-wrap text-uppercase">
                        Total Confirmed Booking
                      </h6>
                      <h1 class="display-4">{bookingDetail?.total}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row mb-3 justify-content-center">
                <div class="col-xl-3 col-sm-6 py-2">
                  <div class="card">
                    <div class="card-body">
                      <p
                        onClick={getPastBooking}
                        className="btn btn-outline-dark text-uppercase 1text-muted small mb-2"
                      >
                        Past
                      </p>
                      {/* <p class="text-uppercase small mb-2">
                        <strong>Past</strong>
                      </p> */}
                      <h5 class="mb-0">
                        <strong>{bookingDetail?.completed}</strong>
                        {/* <small class="text-success ms-2">
                          <i class="fas fa-arrow-up fa-sm pe-1"></i>13,48%
                        </small> */}
                      </h5>

                      <hr />

                      <p
                        onClick={getUpcomingBooking}
                        class="btn btn-outline-dark text-uppercase 1text-muted small mb-2"
                      >
                        Upcoming
                      </p>
                      <h5 class="text-muted mb-0">{bookingDetail?.upcoming}</h5>

                      <hr />

                      <p class="text-uppercase text-muted small mb-2">
                        Potential Earning
                      </p>
                      <h5 class="text-muted mb-0">
                        {bookingDetail?.potential_earning}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 py-2">
                  <div class="card">
                    <div class="card-body">
                      <p
                        onClick={getCancelledBooking}
                        class="btn btn-outline-dark text-uppercase 1text-muted small mb-2"
                      >
                        <strong>Cancelled</strong>
                      </p>
                      <h5 class="mb-0">
                        <strong>{bookingDetail?.total_cancelled}</strong>
                      </h5>

                      {/* <hr />

                      <p class="text-uppercase text-muted small mb-2">
                        Cancelled By You
                      </p>
                      <h5 class="text-muted mb-0">
                        {bookingDetail?.cancelled_by_owner}
                      </h5>

                      <hr />

                      <p class="text-uppercase text-muted small mb-2">
                        Cancelled By Rider
                      </p>
                      <h5 class="text-muted mb-0">
                        {bookingDetail?.cancelled_by_rider}
                      </h5> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* {loadingBookings && <div>Loading...</div>} */}
              {!loadingBookings && lastPage === 1 && result?.length <= 0 && (
                <div className="text-center">
                  <strong className="text-center text-danger">
                    No Bookings
                  </strong>
                </div>
              )}
              {/* {lastPage > 0 && result?.length > 0 && ( */}
              {/* {!loadingBookings && ( */}
              {showBookings && (
                <DashboardListBookings
                  bookings={result}
                  loading={loadingBookings}
                  sendFetchMoreToParent={handleFetchMore}
                ></DashboardListBookings>
              )}
              {/* {!loadingBookings && (
                <DashboardListBookings
                  bookings={result}
                  loading={loadingBookings}
                  sendFetchMoreToParent={handleFetchMore}
                ></DashboardListBookings>
              )} */}
              {/* )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
