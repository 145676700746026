import { AxiosRequestConfig } from "axios";

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: "https://hamrahi.in/api",
  // baseURL: "http://localhost:8000/api",
  // baseURL: "http://trekshare.com/api",
  responseType: "json",

  headers: {
    "Content-Type": "application/json",
  },
};
