import AuthUser from "../Common/AuthUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import UserProfile from "./UserProfile";
import UserBookings from "./UserBookings";
import RolesConstants from "../../constants/RolesConstants";

export default function UserAccount() {
  const PROFILE = "P";
  const BOOKING = "B";
  const { user } = AuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  let profile =
    location.state && location.state.user_id ? location.state : null;
  console.log(user.role);
  const [showElement, setShowElement] = useState(PROFILE);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const dashboardService = useService(DashboardService);

  const loadProfile = () => {
    handleClose();
    setShowElement(PROFILE);
  };

  const loadBookings = () => {
    handleClose();
    setShowElement(BOOKING);
  };
  return (
    <div className="container1" id="main">
      <Offcanvas className="bg-dark" show={show} onHide={handleClose}>
        <Offcanvas.Header className="text-dark bg-warning" closeButton>
          <Offcanvas.Title className="1text-dark">My Account</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav flex-column sticky-top pl-0 mt-3">
            <li className="nav-item">
              <a
                className={
                  showElement === PROFILE ? "nav-link text-primary" : "nav-link"
                }
                onClick={loadProfile}
                href="#"
              >
                Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === BOOKING ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={loadBookings}
              >
                My Bookings
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      {/* /////////////// */}
      {/* <div className="row1"> */}
      {/* {!show && ( */}
      <div className="rounded-0 btn btn-dark" onClick={handleShow}>
        {/* <FontAwesomeIcon
            className="h-25 text-white px-1"
            icon={faArrowRight}
          /> */}
        {">"}
      </div>
      {/* )} */}
      {/* <div className="col" id="main"> */}
      {showElement === PROFILE && <UserProfile profile={profile}></UserProfile>}
      {showElement === BOOKING && user?.role === RolesConstants.RIDER && (
        <UserBookings></UserBookings>
      )}
      {/* </div> */}
    </div>
    // {/* </div> */}
  );
}
