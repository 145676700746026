import { EMPTY, Observable, throwError } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import Ride from "../models/Ride";
import { map } from "rxjs/operators";

export default class DashboardService
  extends ErrorHandling
  implements IService
{
  constructor(context: MessageContextProps) {
    super(context);
  }

  get<Ride>(id: string): Observable<Ride[]> {
    return api.get<Ride[]>(`/rides/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        // console.log(err);
        return [];
        // throw err;
      })
    );
  }

  getMyRides<Ride>(filters: object): Observable<Ride[]> {
    return api.get<Ride[]>(`/dashboard/rides/mine`, {}, filters).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        // console.log(err);
        return [];
        // throw err;
      })
    );
  }

  getRideOverview(): Observable<void> {
    return api.get<void>(`/dashboard/ride/overview`).pipe(
      catchError((err: any) => {
        this.handleError("Ride Overview error");
        // console.log(err);
        // throw err;
        return [];
      })
    );
  }

  getBookingOverview(): Observable<void> {
    return api.get<void>(`/dashboard/booking/overview`).pipe(
      catchError((err: any) => {
        this.handleError("Booking Overview error");
        // console.log(err);
        // throw err;
        return [];
      })
    );
  }

  getRideDashboard(): Observable<void> {
    return api.get<void>(`/dashboard/ride/detail`).pipe(
      catchError((err: any) => {
        this.handleError("Ride dashboard error");
        // console.log(err);
        return [];
        // throw err;
      })
    );
  }

  getBookingDashboard(): Observable<void> {
    return api.get<void>(`/dashboard/booking/detail`).pipe(
      catchError((err: any) => {
        this.handleError("Booking dashboard error");
        // console.log(err);
        return [];
        // throw err;
      })
    );
  }

  // post<Ride>(arg: Ride): Observable<Ride[]> {
  post(obj: object): Observable<Ride> {
    console.log(obj);
    let response = api
      .post<any>(`/rides`, obj)
      .pipe
      // catchError((err: any) => {
      // this.handleError("Ride error");
      //   // console.log(err);
      // return err;
      // })
      ();
    // console.log(response);
    return response;
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }
  // post<T>(arg: T): Observable<T[]> {
  //   throw new Error("Method not implemented.");
  // }
  delete<T>(id: string): Observable<T[]> {
    throw new Error("Method not implemented.");
  }

  login<User>(email: string, password: string): Observable<User[] | void> {
    return api.post<User[]>(`/login`, { email, password }).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        // console.log(err);
        return [];
      })
    );
  }

  fetchMyReceivedBookings<Booking>(query: any): Observable<Booking[]> {
    return api
      .get<Booking[]>(`/dashboard/operator/bookings`, undefined, query)
      .pipe(
        catchError((err: any) => {
          this.handleError("Fetching error");
          console.log(err);
          return [];
        })
      );
  }
}
