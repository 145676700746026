import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import Ride from "../../models/Ride";
import PathConstants from "../../routes/PathConstants";
import "../../styles/create-ride-form.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardService from "../../Services/DashboardService";
import VehicleService from "../../Services/VehicleService";
import { toast } from "react-toastify";

export default function CreateUpdateVehicle() {
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [vehicles, setVehicles] = useState({});

  const vehicleService = useService(VehicleService);
  const createVehicle = event => {
    const form = event.currentTarget;
    // console.log(form);
    if (form.checkValidity() === false) {
      // event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      vehicleService.post(inputs).subscribe({
        next: response => {
          if (response.status) {
            var vehicle = response.result;
            if (vehicle) {
              toast.dismiss();
              toast.success(response.message);
            }
          }
        },
        error: e => alert(e),
        complete: () => console.info("complete"),
      });
    }
  };
  const handleChange = event => {
    const name = event.target.name;
    var value = event.target.value;
    // if (name === "age") {
    //   value = parseInt(value);
    // }
    setInputs(values => ({ ...values, [name]: value }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    createVehicle(event);
  };

  useEffect(() => {}, []);

  return (
    <div className="App create-ride-form">
      <div className="mask d-flex align-items-center h-100">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-9 col-xl-6">
              <div className="card" style={{ borderRadius: 15 }}>
                <div className="app-bg-color card-body p-5">
                  <Form
                    onSubmit={handleSubmit}
                    validated={validated}
                    noValidate
                  >
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="brand"
                        placeholder="Vehicle Brand"
                        value={inputs.brand || ""}
                        onChange={handleChange}
                        required
                      />{" "}
                    </div>
                    <hr />
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="vehicle_no"
                        placeholder="Complete Vehicle Number"
                        value={inputs.vehicle_no || ""}
                        onChange={handleChange}
                        required
                        maxLength={12}
                      />
                      <small className="text-muted">Max: 12 Characters</small>
                      {/* <label className="col small text-left text-muted">
                        Specify full number
                      </label> */}
                    </div>
                    <input className="btn btn-dark" type="submit" />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
