const PathConstants = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard", //for driver/operator
  ACCOUNT: "/account", //for user
  CREATE_RIDE: "/addride",
  BOOK_RIDE: "/book/ride",
  BOOKING_DETAIL: "/booking-detail",
  RIDE_DETAIL: "/trip-detail",
  SEARCH_RESULTS: "/search",
  EDIT_PROFILE: "/profile/update",
  ABOUT: "/about",
  PAGE403: "/unauth",
  PAGE404: "/page404",
  EMAIL_VERIFY: "/email/verify",
  FORGOT_PASSWORD: "/password/forgot",
  CONTACT: "/contactus",
};

export default PathConstants;
