const RideStatus = {
  PENDING: "P",
  RUNNING: "R",
  CANCELLED: "C",
  DELETED: "D",

  PENDING_STRING: "PENDING",
  RUNNING_STRING: "RUNNING",
  CANCELLED_STRING: "CANCELLED",
  DELETED_STRING: "DELETED",

  COMPLETED: "COM",
  UPCOMING: "U",
};

export default RideStatus;
