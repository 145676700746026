import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import CreateUpdateDriver from "./CreateUpdateDriver";
import DriverService from "../../Services/DriverService";
import { MDBBtn, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import FiltersConstants from "../../constants/FiltersConstants";
// import { Ripple, initMDB } from "mdb-react-ui-kit";

export default function DashboardDriver() {
  // initMDB({ Ripple });
  const filtersConstants = FiltersConstants();
  const LIST_DRIVER = 1;
  const ADD_DRIVER = 2;
  const [myDrivers, setMyDrivers] = useState();
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });

  const driverService = useService(DriverService);

  useEffect(() => {
    getMyDrivers();
  }, []);

  const getMyDrivers = () => {
    setLoading(true);
    driverService.getMyDrivers().subscribe({
      next: response => {
        if (response.status) {
          console.log(response.result?.data);
          setMyDrivers(response.result?.data);
          setActiveTab(LIST_DRIVER);

          // setBookingDetail("ride detail");
          // setShowElement(OVERVIEW);
        }
        setLoading(false);
      },
      error: err => {},
    });
  };
  const confirmDeleteDriver = id => {
    setOpenConfirmationDialog(true);
    var title = "Delete Driver";

    var body =
      "Do you want to delete the driver? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
      driver_id: id,
    }));
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    // console.log(confirmation);
    if (confirmation) {
      deleteDriver(dialogParams.driver_id);
    }
  }
  const deleteDriver = id => {
    driverService.delete(id).subscribe({
      next: response => {
        if (response.status) {
          toast.dismiss();
          toast.success(response.message);
          getMyDrivers();
          return;
          //  setActiveTab(LIST_DRIVER);
          // setBookingDetail("ride detail");
          // setShowElement(OVERVIEW);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const createNewDriver = () => {
    setActiveTab(ADD_DRIVER);
    setLoading(false);
    return;
  };

  return (
    <div className="contaniner">
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <div className="row justify-content-center">
        <div class="mt-2 px-2 col-10 col-md-8 ">
          {/* <button className="btn btn-light border" onClick={getMyDrivers}>
        List
      </button> */}
          <div class="card text-center">
            <div class="card-header bg-dark">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item">
                  <a
                    className={
                      activeTab === LIST_DRIVER
                        ? "nav-link active"
                        : "text-white nav-link"
                    }
                    onClick={getMyDrivers}
                    href="#"
                  >
                    List
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    className={
                      activeTab === ADD_DRIVER
                        ? "nav-link active"
                        : "text-white nav-link"
                    }
                    onClick={createNewDriver}
                    href="#"
                  >
                    Add New Driver
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              {activeTab === LIST_DRIVER &&
                (loading ? (
                  <div>Loading...</div>
                ) : myDrivers && myDrivers.length > 0 ? (
                  <MDBListGroup light>
                    {myDrivers.map(myDrivers => {
                      return (
                        <div>
                          <MDBListGroupItem
                            key={myDrivers._id}
                            className="app-bg-color rounded mb-2 d-flex justify-content-between align-items-center"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/avatar.jpg"
                                alt=""
                                style={{ width: "45px", height: "45px" }}
                                className="rounded-circle"
                              />
                              <div className="ms-3">
                                <p className="fw-bold mb-1 text-capitalize">
                                  {myDrivers.name}
                                  {myDrivers.age ? ", " + myDrivers.age : ""}
                                  {myDrivers.gender
                                    ? ", " +
                                      filtersConstants.getGenderString(
                                        myDrivers.gender
                                      )
                                    : ""}
                                </p>
                                <p className="text-muted mb-0">
                                  {myDrivers.phone}
                                </p>
                              </div>
                            </div>
                            {/* <div> */}
                            {/* <MDBBtn size="lg" rounded>
                              Edit
                            </MDBBtn> */}
                            {/* ripple ripple-surface ripple-surface-dark */}
                            <div
                              className="btn btn-dark"
                              data-mdb-ripple-init
                              size="sm"
                              rounded
                              color="link"
                              onClick={() => confirmDeleteDriver(myDrivers._id)}
                            >
                              Delete
                            </div>
                            {/* </div> */}
                          </MDBListGroupItem>
                          {/* <hr /> */}
                        </div>
                        // <div
                        //   key={myDrivers._id}
                        //   className="mb-2 row border border-dark rounded"
                        // >
                        //   <h5 class="card-title">{myDrivers.name}</h5>
                        //   <p class="card-text">
                        //     With supporting text below as a natural lead-in to
                        //     additional content.
                        //   </p>
                        //   <div class="btn btn-primary">Edit</div>
                        // </div>
                      );
                    })}
                  </MDBListGroup>
                ) : (
                  <div>No Drivers Found</div>
                ))}
              {activeTab === ADD_DRIVER && (
                <CreateUpdateDriver></CreateUpdateDriver>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
